import React from "react"
import styled from "styled-components";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PostCardContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: ${props => !props.noShadow ? "20px" : "0"};
  padding: 20px 40px;
  border-radius: 25px;
  box-sizing: border-box;
  margin-left: ${props => !props.inline ? "auto" : undefined};
  margin-right: ${props => !props.inline ? "auto" : undefined};
  box-shadow: ${props => !props.noShadow ? "0px 6px 10px rgba(0, 0, 0, 0.25)" : undefined};
`;

const PostCardTitle = styled.h1`
  font-size: ${props => !props.smallTitle ? (!props.hasImage ? "70px" : "50px") : (!props.hasImage ? "50px" : "30px")};
  @media (max-width: 800px) {
    font-size: ${props => !props.smallTitle ? (!props.hasImage ? "40px" : "30px") : (!props.hasImage ? "40px" : "30px")};
  }
  font-weight: 600;
  color: ${props => props.color};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const PostCardSubitle = styled.h1`
  font-size: ${props => !props.hasImage ? "30px" : "24px"};
  @media (max-width: 800px) {
    font-size: ${props => !props.hasImage ? "24px" : "20px"};
  }
  font-weight: 300;
  color: ${props => props.color};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const PostCardAuthorDate = styled.h1`
  font-size: 20px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
  font-weight: 300;
  color: ${props => props.color};
  margin-top: 15px;
  margin-bottom: 0px;
`;

const PostCardExcerpt = styled.p`
  font-size: 17px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
  font-weight: 300;
  color: ${props => props.color};
  margin-top: 15px;
  margin-bottom: 0px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const PostCard = ( { post, inline, smallTitle, excerpt, noShadow } ) => {
  const date = new Date(post.frontmatter.date);

  return <PostCardContainer
    inline={inline}
    noShadow={noShadow}
    style={{
      background: post.frontmatter.background
    }}
  >
    <div style={{ width: "100%" }}>
      {post.frontmatter.titleImage && <GatsbyImage image={getImage(post.frontmatter.titleImage)} objectFit="contain" style={{
        maxWidth: "100%",
        maxHeight: !smallTitle ? "300px" : "200px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }} alt="" />}
      <PostCardTitle color={post.frontmatter.titleColor} smallTitle={smallTitle} hasImage={!!post.frontmatter.titleImage}>{post.frontmatter.title}</PostCardTitle>
      {post.frontmatter.subtitle && <PostCardSubitle hasImage={!!post.frontmatter.titleImage} color={post.frontmatter.titleColor}>{post.frontmatter.subtitle}</PostCardSubitle>}
      <PostCardAuthorDate color={post.frontmatter.titleColor}>{!inline && [post.frontmatter.author, " · "]}{new Intl.DateTimeFormat("default", {
        month: "long",
        year: "numeric",
      }, { timeZone: "UTC" }).format(date)}</PostCardAuthorDate>
      {excerpt && <PostCardExcerpt color={post.frontmatter.titleColor}>{excerpt}</PostCardExcerpt>}
    </div>
  </PostCardContainer>;
};
