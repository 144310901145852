import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react";

import { MDXProvider } from "@mdx-js/react"
import Nav from "../../components/nav"
import Content from "../../components/content";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import StyledA from "../../components/styleda";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PostCard } from "../../components/post";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Disqus } from 'gatsby-plugin-disqus';
import Giscus from '@giscus/react';

import loadable from "@loadable/component";
import Responsive from "../../components/responsive";
const ReactEmbedGist = loadable(() => import("react-embed-gist"));

const GistWrapper = styled.div`
  white-space: normal;
  & > article > h2 {
    display: none;
  }
`;

const SetFootnotesContext = React.createContext();

const FootnoteCode = props => {
  const refName = props.id;
  const addFootnote = useContext(SetFootnotesContext);
  useEffect(() => {
    addFootnote(refName, props.children);
  }, [refName, props.children, addFootnote]);
  return <li id={props.id}>{props.children}</li>;
};

const Code = props => {
  const lang = (props.className && props.className.startsWith("language-")) ? props.className.split("language-")[1] : undefined;
  if (lang === "gist") {
    const split = props.children.trim().split("/");
    return <GistWrapper>
      <ReactEmbedGist gist={split[0] + "/" + split[1]} file={split[2]} />
    </GistWrapper>;
  } else {
    return (
      <SyntaxHighlighter language={lang} style={atomOneDark} customStyle={{
        borderRadius: "20px",
        padding: "20px",
        fontSize: "18px",
        lineHeight: "145%"
      }} codeTagProps={{style: {fontFamily: "'JetBrains Mono', monospace"} }}>
        {props.children.trim()}
      </SyntaxHighlighter>
    );
  }
};

const InlineCode = styled.code`
  font-family: 'JetBrains Mono', monospace;
  background-color: #e4e4e4;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
`;

const Quote = styled.blockquote`
  font-style: italic;
`;

const H2Wrapper = styled.h2`
  font-size: 40px;
  line-height: 135%;
  margin-bottom: 0;
`;

const H3Wrapper = styled.h2`
  font-size: 30px;
  line-height: 115%;
  margin-bottom: 0;
`;

const ULWrapper = styled.ul`
  margin-top: -15px;
`;

const SetActiveHeaderContext = React.createContext();

function elementText(element) {
  if (typeof element === "string") {
    return element;
  } else if (Array.isArray(element)) {
    return element.map(elementText).join("");
  } else {
    if (element.props && element.props.children) {
      return elementText(element.props.children);
    }
  }
}

const H2 = props => {
  const ref = useRef();
  const setHeader = useContext(SetActiveHeaderContext);

  useLayoutEffect(() => {
    const stringifiedChildren = elementText({props});
    setHeader(stringifiedChildren, ref.current);

    return () => {
      setHeader(stringifiedChildren, null);
    };
  }, [ref, setHeader, props]);

  return <H2Wrapper {...props} ref={ref}/>;
}

const H3 = props => {
  const ref = useRef();
  const setHeader = useContext(SetActiveHeaderContext);

  useLayoutEffect(() => {
    const stringifiedChildren = elementText({props});
    setHeader(stringifiedChildren, ref.current);

    return () => {
      setHeader(stringifiedChildren, null);
    };
  }, [ref, setHeader, props]);

  return <H3Wrapper {...props} ref={ref}/>;
}

const Image = props => {
  if (props.className) {
    return <img {...props}/>;
  } else {
    return <Responsive comp={(big) => {
      return <img src={props.src} className="gatsby-resp-image-wrapper" loading="lazy" decoding="async" style={{
        width: big ? "85%" : "100%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        height: "auto",
        borderRadius: "10px"
      }} />
    }}/>;
  }
}

const Sidenote = styled.span`
  clear: both;
  position: relative;
  float: right;
  box-sizing: border-box;

  width: calc((100vw - min(100vw - 40px, 800px)) / 2 - 8px);
  margin-right: calc(min(
    ((100vw - min(100vw - 40px, 800px)) / 2) - 8px,
    300px
  ) * -1);
  margin-bottom: 10px;
  max-width: 300px;

  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 1200px) {
    width: 200px;
    margin-right: 0;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  font-size: 75%;
  line-height: 145%;
  font-weight: 300;
  color: #505050;

  & > .footnote-backref {
    display: none;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const FootnoteNotLink = styled.sup`
  line-height: 0;
  font-size: 15px;
  margin-left: 3px;
  position: relative;
  top: -2px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const FootnoteLink = styled.sup`
  line-height: 0;
  font-size: 15px;
  margin-left: 2px;
  position: relative;
  top: -2px;

  display: none;
  @media (max-width: 800px) {
    display: initial;
  }
`;

const RecordedFootnotesContext = React.createContext({});

const Footnote = props => {
  const refNum = props.id.split("fnref-")[1];
  const refName = "fn-" + refNum;
  const footnotes = useContext(RecordedFootnotesContext);
  return <>
    <FootnoteNotLink>{refNum}</FootnoteNotLink>
    <FootnoteLink id={props.id}><StyledA href={`#fn-${refNum}`}>{refNum}</StyledA></FootnoteLink>
    {footnotes[refName] && <Sidenote>{refNum}. {footnotes[refName]}</Sidenote>}
  </>;
};

const PostContent = styled.div`
  line-height: 145%;
  & > .footnotes {
    display: none;
  }

  @media (max-width: 800px) {
    & > .footnotes {
      display: initial;
    }
  }

  .gatsby-resp-image-wrapper {
    width: 85%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .mermaid {
    width: 85%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .mermaid > svg {
    max-width: initial !important;
  }

  @media (max-width: 800px) {
    .gatsby-resp-image-wrapper {
      width: 100%;
    }

    .mermaid {
      width: 100%;
    }
  }

  .gatsby-resp-image-wrapper + em {
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    text-align: center;
    display: block;
    line-height: 1.5;
    margin-top: 10px;
  }

  .mermaid + p > em {
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    text-align: center;
    display: block;
    line-height: 1.5;
    margin-top: 10px;
  }

  .mermaid + p {
    margin-top: 0;
  }
`;

const TocBar = styled.span`
  clear: none;
  float: left;
  box-sizing: border-box;

  width: calc((100vw - 100%) / 2);
  margin-left: calc(min(
    (100vw - 100%) / 2,
    300px
  ) * -1);
  max-width: 300px;

  padding-left: 30px;
  padding-right: 20px;

  font-size: 80%;
  line-height: 135%;
  font-weight: 600;
  color: #505050;

  position: sticky;
  top: 100px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const Post = ({ data: { mdx: { slug, body, headings, excerpt, frontmatter } } }) => {
  const [footnotes, setFootnotes] = React.useState({});
  const addFootnote = useCallback((name, footnote) => {
    setFootnotes((old) => {
      return { ...old, [name]: footnote }
    });
  }, [setFootnotes]);

  const [headerMap, setHeaderMap] = React.useState({});
  const setHeader = useCallback((name, ref) => {
    setHeaderMap((old) => {
      return { ...old, [name]: ref }
    });
  }, [setHeaderMap]);

  const [activeHeader, setActiveHeader] = React.useState(null);

  useLayoutEffect(() => {
    const listener = _ => {
      const headersPast = headings.filter(h => {
        const headerElement = headerMap[h.value];
        if (headerElement) {
          const headerTop = headerElement.getBoundingClientRect().top;
          return headerTop <= 200;
        }
        return false;
      });

      if (headersPast.length > 0) {
        const lastHeader = headersPast[headersPast.length - 1];
        setActiveHeader(lastHeader.value);
      } else {
        setActiveHeader(null);
      }
    };

    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    }
  }, [headings, headerMap]);

  const rendered = useMemo(() => {
    return <MDXProvider components={{
      code: Code,
      a: StyledA,
      blockquote: Quote,
      inlineCode: InlineCode,
      h2: H2,
      h3: H3,
      sup: Footnote,
      li: FootnoteCode,
      img: Image,
      ul: ULWrapper,
    }}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  }, [body]);

  return (<div>
    <Nav current="writing" customNav={frontmatter.title}/>
    <Helmet>
      <meta name="description" content={excerpt}/>
      <meta property="og:title" content={frontmatter.title} />
      <meta property="og:url" content={`https://www.shadaj.me/writing/${slug}/`} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={`https://www.shadaj.me/og-image/writing/${slug}.png`} />
      <meta property="og:image:width" content={frontmatter.ogWidth || "600"} />
      <meta property="og:image:height" content={frontmatter.ogWidth ? Math.round(frontmatter.ogWidth / 2) : "300"} />
      <meta name="twitter:card" content="summary_large_image"></meta>
    </Helmet>
    <Content style={{
      maxWidth: "800px"
    }}>
      <PostCard post={{ frontmatter }}/>
      <PostContent>
        <TocBar>
          {headings.map((heading) => {
            return <button key={heading.value} style={{
              color: heading.value === activeHeader ? "#27aa86" : "inherit",
              transform: heading.value === activeHeader ? "scale(1.05)" : "scale(1)",
              transformOrigin: "left",
              fontWeight: "600",
              transition: "all 0.2s ease-in-out",
              WebkitTransition: "all 0.2s ease-in-out",
              marginTop: "6px",
              marginBottom: "6px",
              display: "block",
              cursor: "pointer",
              background: "none",
              border: "none",
              font: "inherit",
              textAlign: "inherit",
              fontSize: heading.depth === 2 ? "inherit": "85%",
              lineHeight: heading.depth === 2 ? "inherit": "120%",
              marginLeft: heading.depth === 2 ? "0": "10px",
            }} onClick={(_) => {
              window.scrollTo({
                left: 0,
                top: window.scrollY + headerMap[heading.value].getBoundingClientRect().top - 100,
                behavior: "smooth"
              });
            }}>{heading.value}</button>
          })}
        </TocBar>
        <SetFootnotesContext.Provider value={addFootnote}>
          <RecordedFootnotesContext.Provider value={footnotes}>
            <SetActiveHeaderContext.Provider value={setHeader}>
              {rendered}
              { frontmatter.useDisqus ? <Disqus
                config={{
                  url: `https://www.shadaj.me/writing/${slug}`,
                  identifier: slug,
                  title: frontmatter.title
                }}
              /> : <Giscus
                id="comments"
                repo="shadaj/website-comments"
                repoId="R_kgDOHkmJVA"
                category="Blog Posts"
                categoryId="DIC_kwDOHkmJVM4CP6pv"
                mapping="specific"
                term={slug}
                reactionsEnabled="1"
                emitMetadata="0"
                inputPosition="top"
                theme="light"
                lang="en"       
              /> }
            </SetActiveHeaderContext.Provider>
          </RecordedFootnotesContext.Provider>
        </SetFootnotesContext.Provider>
      </PostContent>
    </Content>
  </div>);
};

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      slug
      excerpt
      frontmatter {
        titleImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        subtitle
        background
        titleColor
        author
        date
        ogWidth
        useDisqus
      }
      body
      headings {
        depth
        value
      }
    }
  }
`;

export default Post;
